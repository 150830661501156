<template>
  <v-container
    id="notifications"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-row>
      <v-col cols="12">
        <v-col cols="12">
          <v-card style="margin-top: 0; padding: 20px;">
            <v-col cols="12">
              <h4>
                • {{ str['send_notifications_clients_section'] }}
              </h4>
              <div class="mt-6 mb-2">
                <h4>
                  {{ str['customize_notification'] }}
                </h4>
              </div>
              <div>
                <v-text-field
                  v-model="notification.title"
                  :label="str['notification_title']"
                />
              </div>
              <div>
                <v-textarea
                  v-model="notification.body"
                  :label="str['notification_message']"
                  rows="3"
                />
              </div>
              <div>
                <v-select
                  v-model="selectedClients"
                  :items="clients"
                  item-text="label"
                  item-value="value"
                  :label="str['select_clients']"
                  multiple
                  :hint="str['select_clients_to_send_notification']"
                  persistent-hint
                ></v-select>
              </div>
              <div class="mt-6 text-center">
                <v-btn
                  color="success"
                  style="margin-right: 10px;"
                  :disabled="!selectedClients.length"
                  @click="selectMode(selectedClients)"
                >
                  {{ str['send_to_selected_clients'] }}
                </v-btn>
                <v-btn
                  color="orange"
                  @click="selectMode(null)"
                >
                  {{ str['send_to_all_clients'] }}
                </v-btn>
              </div>
              <div
                v-if="success"
                class="mt-5 text-center success--text"
              >
                {{ str['notification_sent_clients'] }}
              </div>
              <div
                v-if="failed"
                class="mt-5 text-center warning--text"
              >
                {{ str['notification_needs_title_message_warning'] }}
              </div>
            </v-col>
          </v-card>
        </v-col>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogMode"
      persistent
      max-width="450px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['send'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                v-if="modes.indexOf('push_notification') > -1"
                cols="12"
              >
                <v-btn
                  color="success"
                  @click="sendPushNotification()"
                  class="row-full-width label-text-break"
                >
                  {{ str['push_notification'] }}
                </v-btn>
              </v-col>
              <v-col
                v-if="modes.indexOf('internal_notification') > -1"
                cols="12"
              >
                <v-btn
                  color="warning"
                  @click="sendInternalNotification()"
                  class="row-full-width label-text-break"
                >
                  {{ str['internal_notification'] }}
                </v-btn>
              </v-col>
              <v-col
                v-if="modes.indexOf('email') > -1"
                cols="12"
              >
                <v-btn
                  color="blue"
                  @click="sendEmail()"
                  class="row-full-width label-text-break"
                >
                  {{ str['email'] }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="default"
                  @click="dialogMode = false"
                  class="row-full-width"
                >
                  {{ str['cancel'] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import axios from 'axios'

  export default {
    data () {
      const user = Utils.getUser()
      const filterEmployee = user && user.configurations && user.configurations.employees && user.configurations.employees.filter_assign_clients ? true : false
      let modes = ['push_notification']
      if (user && user.configurations && user.configurations.notifications && user.configurations.notifications.modes) {
        modes = user.configurations.notifications.modes
      }
      return {
        user: user,
        str: window.strings,
        filterEmployee: filterEmployee,
        modes: modes,
        clients: [],
        selectedClients: [],
        activeStatus: 0,
        notification: {
          title: '',
          body: '',
        },
        success: false,
        failed: false,
        dialogMode: false,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getClients()
    },
    methods: {
      getClients: function () {
        const self = this
        self.$isLoading(true)
        Api.getClients({
          status: this.activeStatus,
          fields: ['id', 'name', 'email', 'employee1', 'employee2'],
          useCache: true,
          filterEmployee: this.filterEmployee
        }, function (response) {
          const clients = []
          self.$isLoading(false)
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              clients.push({
                value: response.data[i].id,
                label: response.data[i].id + ' - ' + response.data[i].name,
                name: response.data[i].name,
                email: response.data[i].email,
              })
            }
            self.clients = clients
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      selectMode: function (clients) {
        this.clientsToSend = clients
        if (this.modes.length > 1) {
          this.dialogMode = true
        } else {
          switch (this.modes[0]) {
            case 'push_notification':
              this.sendPushNotification()
              break;
            case 'internal_notification':
              this.sendInternalNotification()
              break;
            case 'email':
              this.sendEmail()
              break;
          }
        }
      },
      sendPushNotification: function () {
        const self = this
        if (this.sendValidation()) {
          this.$isLoading(true)
          Api.sendAppNotification({
            pt_id: axios.defaults.ptId,
            title: this.notification.title,
            body: this.notification.body,
            users: this.clientsToSend ? this.clientsToSend : this.clients.map(a => a.value)
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.sendSuccess()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      sendInternalNotification: function () {
        const self = this
        if (this.sendValidation()) {
          this.$isLoading(true)
          Api.sendAppManualNotification({
            title: this.notification.title,
            body: this.notification.body,
            badge: 0,
            type: 2,
            users: this.clientsToSend ? this.clientsToSend : this.clients.map(a => a.value)
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.sendSuccess()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      sendEmail: function () {
        const self = this
        if (this.sendValidation()) {
          const users = []
          this.clients.forEach(function (c) {
            if (!self.clientsToSend || (self.clientsToSend && self.clientsToSend.indexOf(c.value) > -1)) {
              users.push({
                name: c.name,
                email: c.email
              })
            }
          })
          this.$isLoading(true)
          Api.sendEmail({
            subject: this.notification.title,
            body: this.notification.body,
            users: users
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.sendSuccess()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      sendValidation: function () {
        const self = this
        if (!this.notification.title || !this.notification.body) {
          this.dialogMode = false
          this.failed = true
          setTimeout(function () {
            self.failed = false
          }, 4000)
          return false
        }
        return true
      },
      sendSuccess: function () {
        const self = this
        this.dialogMode = false
        this.success = true
        setTimeout(function () {
          self.success = false
        }, 4000)
      },
    },
  }
</script>
